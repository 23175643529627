<template>
  <v-col cols="12">
    <crud-filho-list
      :mostra-tabela="!formulario"
      :headers="headers"
      :items="items"
      :paginacao.sync="paginacao"
      :total-registros="totalRegistros"
      :loading="loading"
      :hide-headers="$vuetify.breakpoint.xsOnly"
      @novo-event="novo"
    >
      <template
        slot="items"
        slot-scope="{item}"
      >
        <tr>
          <td
            class="justify-left btnEditar cursorPointer"
            @click="editar(item.item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
            {{ item.item.nomePagador }}
          </td>
          <td
            v-if="item.item.dataPagamento"
            v-data-formatada
            class="justify-center"
          >
            {{ item.item.dataPagamento }}
          </td>
          <td v-else />
          <td
            v-money="{simbolo:'R$', valor:item.item.valorPago}"
            class="justify-center"
          />
          <td class="justify-center">
            {{ item.item.formaPagamento }}
          </td>
          <td>
            <sim-nao-icon
              :ativo="item.item.pago"
              :small="false"
            />
          </td>
        </tr>
      </template>
      <template slot="antesDoBotaoAdicionar">
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="9"
        >
          <v-chip
            color="primary"
            label
            text-color="white"
            :ripple="false"
            class="mr-4"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoItens.valorTotalEvento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorTotalEvento}" />
          </v-chip>
          <v-chip
            color="tertiary"
            label
            text-color="white"
            :ripple="false"
            class="mr-4"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoPagamentos.valorTotalPagamento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorFinalPagamento}" />
          </v-chip>
          <v-chip
            color="warning"
            label
            text-color="white"
            :ripple="false"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoPagamentos.diferencaValor') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: diferencaValor}" />
          </v-chip>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown"
          cols="12"
        >
          <v-chip
            color="primary"
            label
            text-color="white"
            :ripple="false"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoItens.valorTotalEvento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorTotalEvento}" />
          </v-chip>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown"
          cols="12"
        >
          <v-chip
            color="tertiary"
            label
            text-color="white"
            :ripple="false"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoPagamentos.valorTotalPagamento') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: valorFinalPagamento}" />
          </v-chip>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown"
          cols="12"
        >
          <v-chip
            color="warning"
            label
            text-color="white"
            :ripple="false"
          >
            <v-icon left>
              mdi-cash
            </v-icon>
            <span class="pr-1">
              {{ $t('EventoPagamentos.diferencaValor') }}:
            </span>
            <span v-money="{simbolo:'R$', valor: diferencaValor}" />
          </v-chip>
        </v-col>
      </template>
    </crud-filho-list>
    <crud-filho-form
      :mostra-formulario="formulario"
      :is-editting="isEditting"
      :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
      :alerta-validacao="alertValidacao"
      :mensagem-erro="error"
      :mostra-botao-salvar-continuar="false"
      @closeDialogEvent="closeDialog"
      @saveEvent="saveEvent"
      @save-continue-event="saveEvent"
      @deleteEvent="remover"
    >
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="objEditar.nomePagador"
              :label="$t('EventoPagamentos.nomePagador')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-menu
              ref="menuDataPagamento"
              v-model="menuDataPagamento"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dataPagamentoFormatted"
                  :label="$t('EventoPagamentos.dataPagamento')"
                  persistent-hint
                  readonly
                  @blur="objEditar.dataPagamento = parseDate(dataPagamentoFormatted)"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="objEditar.dataPagamento"
                no-title
                dark
                prepend-icon="mdi-calendar-text"
                @input="menuDataPagamento = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="objEditar.valorPago"
              color="primary"
              prepend-icon="mdi-cash"
              :rules="[required]"
              required
              :label="$t('EventoPagamentos.valorPago')"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="objEditar.formaPagamento"
              :rules="[required]"
              required
              prepend-icon="mdi-counter"
              :label="$t('EventoPagamentos.formaPagamento')"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-switch
              v-model="objEditar.pago"
              :label="$t('EventoPagamentos.pago')"
              class="purple-input"
              prepend-icon="mdi-cash-usd"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="objEditar.observacao"
              outlined
              name="observacao"
              :label="$t('EventoPagamentos.observacao')"
              clearable
            />
          </v-col>
        </v-row>
      </v-container>
    </crud-filho-form>
  </v-col>
</template>

<script>

  import Servico from '../services/EventoPagamentosService'
  import EventoItensService from '@/services/EventoItensService'
  import DateUtils from '../utils/DateUtils'
  import SimNaoIcon from '../components/SimNaoIcon'

  export default {
    components:{
      SimNaoIcon
    },
    props: {
      idEvento: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        menuDataPagamento: false,
        alertValidacao: false,
        loadingEndereco: false,
        valorFinalPagamento: 0,
        diferencaValor: 0,
        valorTotalEvento: 0,

        items: [],
        paginacao: null,
        loading: false,
        totalRegistros: 0,
        objPadrao: {
          idPagamento: null,
          idEvento: null,
          nomePagador: null,
          dataPagamento: null,
          valorPago: null,
          formaPagamento: null,
          pago: true,
          observacao: ''
        },
        objEditar: {
          idPagamento: null,
          idEvento: null,
          nomePagador: null,
          dataPagamento: null,
          valorPago: null,
          formaPagamento: null,
          pago: true,
          observacao: ''
        },
        isEditting: false,
        error: null,
        required: value => !!value || 'Campo Obrigatório',
        formulario: false
      }
    },
    computed: {

      dataPagamentoFormatted: function () {
        return DateUtils.formatDate(this.objEditar.dataPagamento)
      },
      headers () {
        return [
          {
            text: this.$i18n.t('EventoPagamentos.nomePagador'),
            value: 'nomePagador',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoPagamentos.dataPagamento'),
            value: 'dataPagamento',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoPagamentos.valorPago'),
            value: 'valorPago',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoPagamentos.formaPagamento'),
            value: 'formaPagamento',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoPagamentos.pago'),
            value: 'pago',
            sortable: false
          }
        ]
      }

    },
    watch: {
      idEvento (val) {
        if (val > 0) {
          this.buscar()
        }
      }

    },
    mounted () {
      if (this.idEvento > 0) {
        this.buscar()
      }
    },
    methods: {

      parseDate (date) {
        return DateUtils.parseDate(date)
      },
      calculaValorFinalPagamento () {
        let valorFinal = 0
        if (!this.items || this.items.length === 0) {
          return valorFinal
        }
        this.items.forEach(item => {
          if (!isNaN(item.valorPago) && item.pago) {
            valorFinal += Number(item.valorPago)
          }
        })

        valorFinal = valorFinal * 100 / 100
        this.valorFinalPagamento = valorFinal
        this.calculaDiferencaValor()
      },
      calculaDiferencaValor() {
        this.valorTotalEvento = 0
        EventoItensService.somaValorVendaCusto(this.idEvento)
          .then( res => {
            this.valorTotalEvento = res.data.valorTotalEvento * 100 / 100
            this.diferencaValor = (this.valorTotalEvento - this.valorFinalPagamento) * 100 / 100
          })
      },
      novo () {
        this.objEditar = Object.assign({}, this.objPadrao)
        this.formulario = true
        this.editedIndex = -1
      },

      editar (registro) {
        this.editedIndex = this.items.indexOf(registro)
        Servico.findById(registro)
          .then(registro => {
            this.objEditar = Object.assign({}, registro.data)
            this.formulario = true
            this.isEditting = true
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
      },

      closeDialog () {
        this.formulario = false
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)
      },
      saveEvent () {
        this.formulario = false
        this.isEditting = false

        this.objEditar.idEvento = this.idEvento

        if (this.editedIndex > -1) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
        this.closeDialog()
      },

      remover () {
        const endereco = this.objEditar
        try {
          Servico.delete(endereco).then(response => {
            this.$root.$emit('mostra_dialogo_alerta', {
              dialogoTitulo: this.$i18n.t('padrao_sucesso'),
              dialogoDescricao: this.$i18n.t('padrao_registro_removido_sucesso')
            })
            this.closeDialog()
            this.buscar()
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
          })
        }
      },
      inserir (registro) {
        try {
          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$i18n.t('padrao_sucesso'),
                dialogoDescricao: this.$i18n.t('padrao_registro_salvo_sucesso')
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: '' + error
          })
        }
      },
      atualizar (registro) {
        try {
          Servico.update(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$i18n.t('padrao_sucesso'),
                dialogoDescricao: this.$i18n.t('padrao_registro_atualizado_sucesso')
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + error
          })
        }
      },
      buscar () {
        try {
          const param = {
            pagination: this.paginacao,
            idEvento: this.idEvento
          }
          this.loading = true
          if (!this.idEvento) {
            return
          }

          Servico.findAll(param)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
                })
                return
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
                return
              }
              this.items = response.data.rows
              this.totalRegistros = response.data.count
              this.loading = false
              this.calculaValorFinalPagamento()
            })
            .catch(err => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + err
              }
              )
            })
            .finally(() => {
              this.loading = false
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + error
          })
          this.loading = false
        }
      }
    }
  }
</script>
