import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('eventoPagamentos', pagination)
  },
  insert (item) {
    return Api().post('eventoPagamento', item)
  },
  update (item) {
    return Api().put('eventoPagamento', item)
  },
  delete (item) {
    return Api().delete(`eventoPagamento?id=${item.id}`, item)
  },
  findById (item) {
    const url = `eventoPagamento/${item.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().post('eventoPagamentosParam', param)
  }
}
