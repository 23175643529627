import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('eventoItens', pagination)
  },
  insert (item) {
    return Api().post('eventoItem', item)
  },
  update (item) {
    return Api().put('eventoItem', item)
  },
  delete (item) {
    return Api().delete(`eventoItem?id=${item.id}`, item)
  },
  findById (item) {
    const url = `eventoItem/${item.id}`
    return Api().get(url)
  },
  somaValorVendaCusto (eventoId) {
    const url = `eventoItem/somaValorVendaCusto/${eventoId}`
    return Api().get(url)
  },
  somaQuantidadeItemBloqueado(idEvento, idEventoItem, idItem){
    const url = `eventoItem/somaQuantidadeItemBloqueado/${idEvento}/${idEventoItem}/${idItem}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().post('eventoItensParam', param)
  }
}
